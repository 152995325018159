import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HTTPService } from "./http-service";

@Injectable()
export class ClientService {

    constructor(private httpService: HTTPService) {

    }

    getClients(page: number, term: string = ''): Observable<Object> {
        const limit = 15
        return this.httpService.httpGet(`${this.httpService.getProUrl}/client/list?page=${page*limit}&limit=${limit}&term=${term}`, localStorage.getItem('r-token'))
    }

    getRecruitmentsByClient(clientId: number): Observable<Object> {
      return this.httpService.httpGet(`${this.httpService.getProUrl}/client/recruitments?clientId=${clientId}`, localStorage.getItem('r-token'))
    }

    getListType(): Observable<Object> {
      return this.httpService.httpGet(`${this.httpService.getProUrl}/client/list-type`, localStorage.getItem('r-token'))
    }

    fetchTrialClient( id: string): Observable<Object> {
      return this.httpService.httpPut(`${this.httpService.getBaseUrl}/client/change-trial/${id}`, {}, localStorage.getItem('r-token'))
    }


    delete(id: string): Observable<Object> {
      return this.httpService.httpPut(`${this.httpService.getProUrl}/client/change-status/${id}`, {}, localStorage.getItem('r-token'));
  }

    create(body: FormData): Observable<Object> {
      return this.httpService.httpPostForm(`${this.httpService.getProUrl}/client/create`, body, localStorage.getItem('r-token'))
    }

    update(body: FormData, id: string): Observable<Object> {
      return this.httpService.httpPutForm(`${this.httpService.getProUrl}/client/update-data/${id}`, body, localStorage.getItem('r-token'))
    }

    findOne(id: string): Observable<Object> {
      return this.httpService.httpGet(`${this.httpService.getProUrl}/client/find/${id}`, localStorage.getItem('r-token'))
    }

    sendEmail(body: any): Observable<Object> {
      return this.httpService.httpPostForm(`${this.httpService.getProUrl}/client/send-email`, body, localStorage.getItem('r-token'))
    }

    getClientsByClientType(id: number) {
      const token = localStorage.getItem("r-token");
      return this.httpService.httpGet(
        `${this.httpService.getProUrl}/clients/client-type/${id}`,
        token
      )
    }
}
